import Head from 'next/head';
import ErrorIcon from './svgs/ErrorIcon';

interface InvalidPageProps {
  companyName: string | undefined
  message: string,
  title: string,
}
export default function InvalidPageLinkComponent({ companyName, message, title }: InvalidPageProps) {
  return (
    <>
      <Head>
        <title>Easy ID Verification, User Authentication, and Digital KYC with Smile Identity.</title>
      </Head>
      <section className='max-w-sm md:max-w-lg bg-white rounded-2xl mx-auto shadow-lg border border-stone-300 p-12 flex flex-col text-center'>
        <div className='image-container mb-3 flex justify-center'>
          <ErrorIcon />
        </div>

        <div>
          <h1 data-cy='main-title' className='text-orange-600 text-[16px] font-bold leading-normal'>
            { title }
          </h1>
          { message && (
            <p data-cy="message" className='text-center text-red-800 text-[14px] font-medium leading-none mt-4'>
              { message }
            </p>
          )}

          <p data-cy='body' className='mt-8'>
            Contact the { companyName ? <strong className='capitalize'>{companyName}</strong> : "company's"} support team
          </p>
        </div>
      </section>
    </>
  );
}

InvalidPageLinkComponent.defaultProps = {
  companyName: undefined,
  message: '',
  title: 'Invalid Link'
};