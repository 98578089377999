export default function ErrorIcon() {
    return (
        <svg width="40" height="64" viewBox="0 0 40 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4207 3.52804L7.95801 0.937439L12.4207 3.52804Z" fill="#151F72" />
            <path d="M28.0648 11.1273L8.88979 9.15527e-05L8.15417 0.740263L7.95801 0.937642L12.4207 3.52824L27.7216 12.4103C27.9177 12.5337 28.1139 12.7064 28.31 12.8791C29.1437 13.6439 29.7322 14.8529 29.7322 15.8891L29.6096 56.9686V57.1907C29.6096 57.4127 29.5606 57.5608 29.5116 57.7335C29.4135 58.0542 29.2909 58.3009 29.0702 58.449C29.3154 58.3996 29.5116 58.2763 29.6832 58.0542C29.6096 58.1776 29.5116 58.3009 29.4135 58.3749L30.3943 57.8075C30.7621 57.5854 30.9828 57.1413 30.9828 56.4998L31.1054 16.3826C31.1054 14.4581 29.7568 12.0896 28.0894 11.1273H28.0648Z" fill="#91190F" />
            <path d="M28.2887 12.8791C28.0926 12.7064 27.9209 12.5337 27.7002 12.4103L12.424 3.52826L9.30985 1.72718C8.52519 1.2584 7.51985 1.40644 6.88232 2.04792L1.38972 7.54986L1.02191 7.91995L0.923828 40.5368C0.923828 40.8576 0.972869 41.203 1.07095 41.5237C1.3652 42.5353 2.07629 43.5469 2.90999 44.0156L18.0392 52.799L27.5531 58.3256C27.7493 58.449 27.9454 58.4736 28.1171 58.523C28.4604 58.597 28.7791 58.597 29.0244 58.4243C29.245 58.2763 29.3922 58.0295 29.4657 57.7088C29.5148 57.5361 29.5638 57.3881 29.5638 57.166V56.944L29.6864 15.8645C29.6864 14.8282 29.0734 13.6193 28.2642 12.8544L28.2887 12.8791Z" fill="#F8CEEB" />
            <path d="M16.1746 39.3278C17.3271 39.3525 17.8175 38.1189 17.2781 36.5645C16.8857 35.4296 16.0765 34.4427 15.2183 33.9739C14.8996 33.8012 14.5563 33.6779 14.2375 33.6779C13.085 33.6532 12.5946 34.8868 13.1341 36.4412C13.5264 37.5761 14.3356 38.563 15.1938 39.0318C15.5126 39.2045 15.8559 39.3278 16.1746 39.3278Z" fill="#91190F" />
            <path d="M14.2143 32.3209C13.6503 31.9755 13.2825 31.334 13.258 30.6679L13.0128 23.8583C12.9637 22.2793 14.6556 21.243 16.0288 22.0326L16.6418 22.378C17.3039 22.7727 17.6962 23.4882 17.6471 24.2531L17.2548 30.7172C17.1567 32.2469 15.4893 33.1351 14.1897 32.3209H14.2143Z" fill="#91190F" />
            <path d="M8.89494 0L2.05371 7.40171L7.52179 10.5845C7.74247 10.7078 7.52179 11.3246 7.74247 11.3493C7.93864 11.374 8.45357 11.0286 8.72329 10.8065C9.06658 10.5351 9.48343 10.4117 9.63055 9.89362C9.67959 9.69625 9.75316 9.52354 9.75316 9.30149V0.468775L8.89494 0Z" fill="#F8CEEB" />
            <path d="M8.32773 0.59158L0.996094 7.94395L6.46417 11.1267C6.68486 11.25 6.90554 11.3241 7.1017 11.3734C7.29787 11.3981 7.49403 11.3981 7.66568 11.3734C8.10705 11.2994 8.42581 10.9786 8.57294 10.4605C8.62198 10.2632 8.69554 10.0904 8.69554 9.86839V0.17215L8.30321 0.59158H8.32773Z" fill="#91190F" />
            <mask id="mask0_1923_23198" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x="20" y="42" width="20" height="22">
                <path d="M37.0658 42.683H22.3535C21.243 42.683 20.3428 43.5888 20.3428 44.7062V61.9769C20.3428 63.0942 21.243 64 22.3535 64H37.0658C38.1762 64 39.0765 63.0942 39.0765 61.9769V44.7062C39.0765 43.5888 38.1762 42.683 37.0658 42.683Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1923_23198)">
                <path d="M26.2767 44.4096C28.9004 49.2207 31.5241 54.0565 34.1478 58.8922C34.3194 59.213 34.2459 59.6818 33.9026 60.1752C33.167 61.2114 31.4996 62.0503 30.249 62.0256C29.6115 62.0256 29.1946 61.7789 29.0475 61.4335C26.8161 56.4003 24.6093 51.3918 22.3779 46.3587C22.5251 46.7041 22.8683 46.9508 23.4078 47.0248C24.4377 47.1729 25.7372 46.5314 26.2277 45.5938C26.4729 45.1497 26.4729 44.7056 26.3012 44.3849L26.2767 44.4096Z" fill="#FF5805" />
                <path d="M25.2221 43.8175C26.2275 43.8915 26.6934 44.681 26.1784 45.5939C25.688 46.5068 24.3884 47.1729 23.3586 47.0249C22.3287 46.8768 21.9609 46.038 22.5004 45.1991C23.0398 44.3356 24.2168 43.7435 25.2221 43.8421V43.8175Z" fill="#91190F" />
            </g>
            <path d="M27.0856 31.1366C29.6602 38.9577 27.9193 45.3479 23.2113 46.6308C22.5738 46.8035 21.8872 46.8776 21.1516 46.8529C20.416 46.8529 19.6559 46.7049 18.8957 46.4828C17.8904 46.1867 16.8605 45.7426 15.8307 45.1505C11.1718 42.4612 6.73355 36.8359 4.62478 30.4211C1.70684 21.539 4.35506 14.5074 10.5588 14.7048C12.2752 14.7541 14.0897 15.3709 15.8797 16.4072C20.5141 19.0965 24.9768 24.7218 27.0856 31.1366ZM19.9256 43.1274C24.6826 43.2754 26.7423 37.8721 24.4864 31.0626C22.8681 26.1528 19.4597 21.8351 15.8797 19.7626C14.5066 18.9731 13.1089 18.5043 11.7848 18.455C7.02779 18.3069 4.96807 23.7102 7.22396 30.5198C8.84231 35.4542 12.2507 39.7472 15.8307 41.8197C17.2038 42.6092 18.6015 43.078 19.9256 43.1274Z" fill="#FF5805" />
            <path d="M30.7405 28.99C28.6318 22.5752 24.169 16.9745 19.5347 14.2606C17.7447 13.2243 15.9301 12.6075 14.2137 12.5582C12.8651 12.5088 11.6881 12.8049 10.7073 13.3971L7.05371 15.5435C8.03453 14.9761 9.21152 14.68 10.5601 14.7047C12.2766 14.754 14.0911 15.3708 15.8811 16.4071C20.5155 19.0964 24.9782 24.7217 27.087 31.1365C29.3919 38.0941 28.2394 43.9168 24.6594 46.0386L28.313 43.8921C31.893 41.795 33.0455 35.9723 30.7405 28.99Z" fill="#91190F" />
        </svg>
    );
}